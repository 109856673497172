<template>
  <a-drawer
    title="发票"
    placement="right"
    :closable="true"
    @close="close"
    :visible="visible"
    :class="[{ 'ant-drawer__have-footer': isConfirm }]"
    width="480"
  >
    <div class="mb-[16px]" v-for="item of invoiceData" :key="item.id">
      <a-card>
        <template #title>
          <span class="mr-[8px]">默认</span>
          <span>
            <a-switch
              :disabled="item.checked"
              checked-children="开"
              un-checked-children="关"
              v-model:checked="item.checked"
              @change="setDefaultClick(item)"
            />
          </span>
        </template>
        <template #extra>
          <span class="cursor-pointer" @click="editInvoiceClick(item)">
            <EditOutlined></EditOutlined>
          </span>
          <span class="ml-[16px] cursor-pointer" v-if="!item.checked" @click="deleteInvoiceClick(item)">
            <CloseOutlined></CloseOutlined>
          </span>
        </template>
        <div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">发票抬头</div>
            <div class="w-7/10">{{ item.invoiceTitle }}</div>
          </div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">纳税人识别号</div>
            <div class="w-7/10">{{ item.taxNo }}</div>
          </div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">公司地址</div>
            <div class="w-7/10">{{ item.companyAddress }}</div>
          </div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">公司电话</div>
            <div class="w-7/10">{{ item.companyPhone }}</div>
          </div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">开户行</div>
            <div class="w-7/10">{{ item.bankName }}</div>
          </div>
          <div class="w-full flex">
            <div class="mr-[8px] text-[#000] text-right w-3/10 font-bold">银行账号</div>
            <div class="w-7/10">{{ item.bankAccount }}</div>
          </div>
        </div>
      </a-card>
    </div>

    <div
      class="border-[1px] border-dashed border-[#d9d9d9] text-center curdor-pointer bg-[#fff]"
      @click="addInvoiceClick"
    >
      <PlusOutlined />
      <span class="ml-[8px]">新建发票</span>
    </div>
    <div class="drawer-footer" v-if="isConfirm">
      <a-button type="primary" @click="returnClick">确定</a-button>
    </div>
    <a-drawer :title="leverTwoTitle" placement="right" :closable="true" v-model:visible="leverTwoVisible" width="480">
      <a-form :model="invoice" :wrapper-col="{ span: 24 }" :rules="invoiceRules" ref="invoiceFormRef">
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">发票抬头</div>
          <a-form-item name="invoiceTitle">
            <a-input :maxlength="20" v-model:value="invoice.invoiceTitle" placeholder="请输入" />
          </a-form-item>
        </div>
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">纳税人识别号</div>
          <a-form-item name="taxNo">
            <a-input
              :maxlength="20"
              @change="() => (invoice.taxNo = invoice.taxNo.replace(/[\W]/g, '').toLocaleUpperCase())"
              v-model:value="invoice.taxNo"
              placeholder="请输入"
            />
          </a-form-item>
        </div>
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">公司地址</div>
          <a-form-item name="companyAddress">
            <a-input :maxlength="20" v-model:value="invoice.companyAddress" placeholder="请输入" />
          </a-form-item>
        </div>
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">公司电话</div>
          <a-form-item name="companyPhone">
            <a-input :maxlength="20" v-model:value="invoice.companyPhone" placeholder="请输入" />
          </a-form-item>
        </div>
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">开户行</div>
          <a-form-item name="bankName">
            <a-input :maxlength="20" v-model:value="invoice.bankName" placeholder="请输入" />
          </a-form-item>
        </div>
        <div class="mb-[24px]">
          <div class="text-[#000] mb-[8px]">银行账号</div>
          <a-form-item name="bankAccount">
            <a-input :maxlength="20" v-model:value="invoice.bankAccount" placeholder="请输入" />
          </a-form-item>
        </div>
      </a-form>
      <div class="drawer-footer">
        <a-button class="mr-[8px]" @click="leverTwoVisible = false">取消</a-button>
        <a-button type="primary" :loading="createInvoiceLoading || editInvoiceLoading" @click="confirmCLick"
          >确定</a-button
        >
      </div>
    </a-drawer>
  </a-drawer>
</template>

<script lang="ts" setup>
import { PropType, reactive, ref } from 'vue'
import { Card as ACard, Switch as ASwitch, Drawer as ADrawer } from 'ant-design-vue'

import { useAjax } from '@vue-mfe/utils'
import { EditOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons-vue'

// @ts-ignore
const props = defineProps(
  {
    visible: Boolean as PropType<boolean>,
    isConfirm: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  }
)
const emit = defineEmits(['confirm', 'update:visible'])

interface Invoice {
  invoiceTitle: string
  taxNo: string
  companyAddress: string
  companyPhone: string
  bankName: string
  bankAccount: string
  id: string
}

const leverTwoVisible = ref(false)
const defaultInvoiceId = ref('')
const operationType = ref('')
const leverTwoTitle = ref('')
let invoice = reactive<Invoice>(
  {
    invoiceTitle: '',
    taxNo: '',
    companyAddress: '',
    companyPhone: '',
    bankName: '',
    bankAccount: '',
    id: ''
  }
)
const invoiceInit = () => {
  invoice.invoiceTitle = ''
  invoice.taxNo = ''
  invoice.companyAddress = ''
  invoice.companyPhone = ''
  invoice.bankName = ''
  invoice.bankAccount = ''
  invoice.id = ''
}
const returnClick = () => {
  emit('confirm', invoiceData.value[0])
}
const invoiceFormRef = ref()
const invoiceRules = reactive(
  {
    invoiceTitle: [{ required: true, message: '请输入发票抬头', trigger: 'blur' }],
    taxNo: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
    companyAddress: [{ required: true, message: '请输入公司地址', trigger: 'blur' }],
    companyPhone: [{ required: true, message: '请输入公司电话', trigger: 'blur' }],
    bankName: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
    bankAccount: [{ required: true, message: '请输入银行账号', trigger: 'blur' }]
  }
)
// 获取开票资料
const { data: invoiceData, run: getInvoiceData } = useAjax(
  {
    action: `GET /client/invoice`,
    convert: {
      client (data) {
        data.forEach(
          (item: any) => {
            if (item.status.code === 'Y') {
              item.checked = true
            } else {
              item.checked = false
            }
          }
        )
        return data
      }
    }
  }
)

const addInvoiceClick = () => {
  invoiceInit()
  leverTwoTitle.value = '新建发票'
  operationType.value = 'new'
  invoiceFormRef.value?.clearValidate()

  leverTwoVisible.value = true
}

const { run: createInvoice, loading: createInvoiceLoading } = useAjax(
  {
    action: `POST /client/invoice`,
    params: () => invoice,
    lazy: true
  }
)
const confirmCLick = () => {
  invoiceFormRef.value.validate().then(
    () => {
      if (operationType.value === 'new') {
        createInvoice().then(
          async () => {
            getInvoiceData().then(
              (res) => {
                if (res.status === 200) {
                  leverTwoVisible.value = false
                }
              }
            )
          }
        )
      }
      if (operationType.value === 'edit') {
        editInvoice().then(
          (res) => {
            if (res.status === 200) {
              leverTwoVisible.value = false
            }
          }
        )
      }
    }
  )
}
const { run: editInvoice, loading: editInvoiceLoading } = useAjax(
  {
    action: `PUT /client/invoice`,
    params: () => invoice,
    lazy: true
  }
)
const editInvoiceClick = (item: any) => {
  invoice = Object.assign(invoice, { ...item })
  leverTwoTitle.value = '编辑发票'
  operationType.value = 'edit'
  invoiceFormRef.value?.clearValidate()
  leverTwoVisible.value = true
}

const { run: setDefaultInvoice } = useAjax(
  {
    action: `PUT /client/invoice/default/:invoiceInfoId`,
    params: () => defaultInvoiceId.value,
    lazy: true
  }
)
const setDefaultClick = (item: any) => {
  if (item.checked) {
    defaultInvoiceId.value = item.id
    setDefaultInvoice().then(
      () => {
        getInvoiceData()
      }
    )
  }
}

const { run: deleteInvoice } = useAjax(
  {
    action: `PUT /client/invoice/enabled/:invoiceInfoId`,
    params: () => defaultInvoiceId.value,
    lazy: true
  }
)
const deleteInvoiceClick = (item: Invoice) => {
  defaultInvoiceId.value = item.id
  deleteInvoice().then(
    () => {
      getInvoiceData()
    }
  )
}

const close = () => {
  emit('update:visible', false)
}
</script>

<style scoped></style>
